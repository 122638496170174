import React from "react";
import { useNavigate } from "react-router-dom";
const TermsAndConditions = () => {
    const navigate = useNavigate();
  return (
    <div>
        <button onClick={() => navigate(-1)}>Zurück</button>
      <div>
        <div>
          <h3>ALLGEMEINE GESCHÄFTSBEDINGUNGEN FÜR DIE HOTELLERIE 2006</h3>
          <p>
            <strong>(AGBH 2006)</strong>
          </p>
          <p>
            <em>Fassung vom 15.11.2006</em>
          </p>
          <h3>Inhaltsübersicht</h3>
        </div>
      </div>

      <div>
        <div>
          <p>
            §01 Geltungsbereich
            <br />
            §02 Begriffsdefinitionen
            <br />
            §03 Vertragsabschluss – Anzahlung
            <br />
            §04 Beginn und Ende der Beherbergung
            <br />
            §05 Rücktritt vom Beherbergungsvertrag – Stornogebühr
            <br />
            §06 Beistellung einer Ersatzunterkunft
            <br />
            §07 Rechte des Vertragspartners
            <br />
            §08 Pflichten des Vertragspartners
            <br />
            §09 Rechte des Beherbergers
            <br />
            §10 Pflichten des Beherbergers
            <br />
            §11 Haftung des Beherbergers für Schäden an eingebrachten Sachen
            <br />
            §12 Haftungsbeschränkungen
            <br />
            §13 Tierhaltung
            <br />
            §14 Verlängerung der Beherbergung
            <br />
            §15 Beendigung des Beherbergungsvertrages – Vorzeitige Auflösung
            <br />
            §16 Erkrankung oder Tod des Gastes im Beherbergungsvertrag
            <br />
            §17 Erfüllungsort, Gerichtsstand und Rechtswahl
            <br />
            §18 Sonstiges
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 1 Geltungsbereich</h3>
          <p>
            1.1 Diese Allgemeinen Geschäftsbedingungen für die Hotellerie (im
            Folgenden „AGBH 2006“) ersetzen die bisherigen ÖHVB in der Fassung
            vom 23. Septem- ber 1981.
          </p>
          <p>
            1.2 Die AGBH 2006 schließen Sondervereinbarungen nicht aus. Die AGBH
            2006 sind gegenüber im Einzelnen getroffenen Vereinbarungen
            subsidiär.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 2 Begriffsdefinitionen</h3>
          <p>
            2.1 Begriffsdefinitionen:
            <br />
            „Beherberger“: Ist eine natürliche oder juristische Person, die
            Gäste gegen Entgelt beherbergt.
          </p>
          <p>
            „Gast“: Ist eine natürliche Person, die Beher- bergung in Anspruch
            nimmt. Der Gast ist in der Regel zugleich Vertragspart- ner. Als
            Gast gelten auch jene Perso- nen, die mit dem Vertragspartner an-
            reisen (zB Familienmitglieder, Freun- de etc).
          </p>
          <p>
            „Vertragspartner“: Ist eine natürliche oder juristische Per- son des
            In- oder Auslandes, die als Gast oder für einen Gast einen Beher-
            bergungsvertrag abschließt.
          </p>
          <p>
            „Konsument“ und „Unternehmer“: Die Begriffe sind im Sinne des Kon-
            sumentenschutzgesetzes 1979 idgF zu verstehen.
          </p>
          <p>
            „Beherbergungs- vertrag“: Ist der zwischen dem Beherberger und dem V
            ertragspartner abgeschlossene Vertrag, dessen Inhalt in der Folge
            nä- her geregelt wird.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 3 Vertragsabschluss – Anzahlung</h3>
          <p>
            3.1 Der Beherbergungsvertrag kommt durch die Annahme der Bestellung
            des Ver- tragspartners durch den Beherberger zustande. Elektronische
            Erklärungen gelten als zugegangen, wenn die Partei, für die sie
            bestimmt sind, diese unter gewöhnli- chen Umständen abr /ufen kann,
            und der Zugang zu den bekannt gegebenen Ge- schäftszeiten des
            Beherbergers erfolgt.
          </p>
          <p>
            3.2 Der Beherberger ist berechtigt, den Beherbergungsvertrag unter
            der Bedingung abzuschließen, dass der Vertragspartner eine Anzahlung
            leistet. In diesem Fall ist der Beherberger verpflichtet, vor der
            Annahme der schriftlichen oder mündlichen Bestellung des
            Vertragspartners, den Vertragspartner auf die geforderte Anzah- lung
            hinzuweisen. Erklärt sich der Vertragspartner mit der Anzahlung
            (schriftlich oder mündlich) einverstanden, kommt der
            Beherbergungsvertrag mit Zugang der Einverständniserklärung über die
            Bezahlung der Anzahlung des Vertragspartners beim Beherberger
            zustande.
          </p>
          <p>
            3.3 Der Vertragspartner ist verpflichtet, die Anzahlung spätestens 7
            Tage (einlan- gend) vor der Beherbergung zu bezahlen. Die Kosten für
            die Geldtransaktion (zB Überweisungsspesen) trägt der
            Vertragspartner. Für Kredit- und Debitkarten gel- ten die jeweiligen
            Bedingungen der Kartenunternehmen.
          </p>
          <p>
            3.4 Die Anzahlung ist eine Teilzahlung auf das vereinbarte Entgelt.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 4 Beginn und Ende der Beherbergung</h3>
          <p>
            4.1 Der Vertragspartner hat das Recht, so der Beherberger keine
            andere Bezugszeit anbietet, die gemieteten Räume ab 15.00 Uhr des
            vereinbarten Tages („Ankunfts- tag“) zu beziehen.
          </p>
          <p>
            4.2 Wird ein Zimmer erstmalig vor 6.00 Uhr Früh in Anspruch
            genommen, so zählt die vorhergegangene Nacht als erste Übernachtung.
          </p>
          <p>
            4.3 Die gemieteten Räume sind durch den Vertragspartner am Tag der
            Abr /eise bis 12.00 Uhr freizumachen. Der Beherberger ist
            berechtigt, einen weiteren Tag in Rechnung zu stellen, wenn die
            gemieteten Räume nicht fristgerecht freigemacht sind.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 5 Rücktritt vom Beherbergungsvertrag – Stornogebühr</h3>
          <p>
            <strong>Rücktritt durch den Beherberger</strong>
            <br />
            5.1 Sieht der Beherbergungsvertrag eine Anzahlung vor und wurde die
            Anzahlung vom Vertragspartner nicht fristgerecht geleistet, kann der
            Beherberger ohne Nachfrist vom Beherbergungsvertrag zurücktreten.
          </p>
          <p>
            5.2 Falls der Gast bis 18.00 Uhr des vereinbarten Ankunftstages
            nicht erscheint, be- steht keine Beherbergungspflicht, es sei denn,
            dass ein späterer Ankunftszeit- punkt vereinbart wurde.
          </p>
          <p>
            5.3 Hat der Vertragspartner eine Anzahlung (siehe 3.3) geleistet, so
            bleiben dagegen die Räumlichkeiten bis spätestens 12.00 Uhr des dem
            vereinbarten Ankunftstages folgenden Tag reserviert. Bei
            Vorauszahlung von mehr als vier Tagen, endet die
            Beherbergungspflicht ab 18 Uhr des vierten Tages, wobei der
            Ankunftstag als erster Tag gerechnet wird, es sei denn, der Gast
            gibt einen späteren Ankunftstag bekannt.
          </p>
          <p>
            5.4 Bis spätestens 3 Monate vor dem vereinbarten Ankunftstag des
            Vertragspartners kann der Beherbergungsvertrag durch den
            Beherberger, aus sachlich gerechtfer- tigten Gründen, es sei denn,
            es wurde etwas anderes vereinbart, durch einseitige Erklärung
            aufgelöst werden.
          </p>
          <p>
            <strong>Rücktritt durch den Vertragspartner – Stornogebühr</strong>
            <br />
            5.5 Bis spätestens 3 Monate vor dem vereinbarten Ankunftstag des
            Gastes kann der Beherbergungsvertrag ohne Entrichtung einer
            Stornogebühr durch einseitige Er- klärung durch den Vertragspartner
            aufgelöst werden.
          </p>
          <p>
            5.6 Außerhalb des im § 5.5. festgelegten Zeitraums ist ein Rücktritt
            durch einseitige Erklärung des Vertragspartners nur unter
            Entrichtung folgender Stornogebühren möglich:
            <br />
            – bis 1 Monat vor dem Ankunftstag 40 % vom gesamten
            Arrangementpreis;
            <br />
            – bis 1 Woche vor dem Ankunftstag 70 % vom gesamten
            Arrangementpreis;
            <br />– in der letzten Woche vor dem Ankunftstag 90 % vom gesamten
            Arrangementpreis.
          </p>
          <p>
            bis 3 Monate: keine Stornoge- bühren
            <br />
            3 Monate bis 1 Monat: 40%
            <br />
            1 Monat bis 1 Woche: 70%
            <br />
            In der letzten Woche: 90%
          </p>
          <p>
            <strong>Behinderungen der Anreise</strong>
            <br />
            5.7 Kann der Vertragspartner am Tag der Anreise nicht im
            Beherbergungsbetrieb erscheinen, weil durch unvorhersehbare
            außergewöhnliche Umstände (zB extre- mer Schneefall, Hochwasser etc)
            sämtliche Anreisemöglichkeiten unmöglich sind, ist der
            Vertragspartner nicht verpflichtet, das vereinbarte Entgelt für die
            Ta- ge der Anreise zu bezahlen.
          </p>
          <p>
            5.8 Die Entgeltzahlungspflicht für den gebuchten Aufenthalt lebt ab
            Anreisemög- lichkeit wieder auf, wenn die Anreise innerhalb von drei
            Tagen wieder möglich wird.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 6 Beistellung einer Ersatzunterkunft</h3>
          <p>
            6.1 Der Beherberger kann dem Vertragspartner bzw den Gästen eine
            adäquate Er- satzunterkunft (gleicher Qualität) zur Verfügung
            stellen, wenn dies dem Ver- tragspartner zumutbar ist, besonders
            wenn die Abweichung geringfügig und sach- lich gerechtfertigt ist.
          </p>
          <p>
            6.2 Eine sachliche Rechtfertigung ist beispielsweise dann gegeben,
            wenn der Raum (die Räume) unbenutzbar geworden ist (sind), bereits
            einquartierte Gäste ihren Aufenthalt verlängern, eine Überbuchung
            vorliegt oder sonstige wichtige betrieb- liche Maßnahmen diesen
            Schritt bedingen.
          </p>
          <p>
            6.3 Allfällige Mehraufwendungen für das Ersatzquartier gehen auf
            Kosten des Be- herbergers.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 7 Rechte des Vertragspartners</h3>
          <p>
            7.1 Durch den Abschluss eines Beherbergungsvertrages erwirbt der
            Vertragspartner das Recht auf den üblichen Gebrauch der gemieteten
            Räume, der Einrichtungen des Beherbergungsbetriebes, die üblicher
            Weise und ohne besondere Bedingun- gen den Gästen zur Benützung
            zugänglich sind, und auf die übliche Bedienung. Der Vertragspartner
            hat seine Rechte gemäß allfälligen Hotel- und/oder Gäste-
            richtlinien (Hausordnung) auszuüben.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 8 Pflichten des Vertragspartners</h3>
          <p>
            8.1 Der Vertragspartner ist verpflichtet, spätestens zum Zeitpunkt
            der Abr /eise das vereinbarte Entgelt zuzüglich etwaiger
            Mehrbeträge, die auf Grund gesonderter Leistungsinanspruchnahme
            durch ihn und/oder die ihn begleitenden Gästen ent- standen sind
            zuzüglich gesetzlicher Umsatzsteuer zu bezahlen.
          </p>
          <p>
            8.2 Der Beherberger ist nicht verpflichtet, Fremdwährungen zu
            akzeptieren. Akzep- tiert der Beherberger Fremdwährungen, werden
            diese nach Tunlichkeit zum Ta- geskurs in Zahlung genommen. Sollte
            der Beherberger Fremdwährungen oder bargeldlose Zahlungsmittel
            akzeptieren, so trägt der Vertragspartner alle damit
            zusammenhängenden Kosten, etwa Erkundigungen bei
            Kreditkartenunterneh- mungen, Telegramme, usw.
          </p>
          <p>
            8.3 Der Vertragspartner haftet dem Beherberger gegenüber für jeden
            Schaden, den er oder der Gast oder sonstige Personen, die mit Wissen
            oder Willen des Vertrags- partners Leistungen des Beherbergers
            entgegennehmen, verursachen.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 9 Rechte des Beherbergers</h3>
          <p>
            9.1 V erweigert der V ertragspartner die Bezahlung des bedungenen
            Entgelts oder ist er damit im Rückstand, so steht dem Beherberger
            das gesetzliche Zurückbehal- tungsrecht gemäß § 970c ABGB sowie das
            gesetzliche Pfandrecht gem § 1101 ABGB an den vom Vertragspartner
            bzw dem vom Gast eingebrachten Sachen zu. Dieses Zurückbehaltungs-
            oder Pfandrecht steht dem Beherberger weiters zur Si- cherung seiner
            Forderung aus dem Beherbergungsvertrag, insbesondere für Ver-
            pflegung, sonstiger Auslagen, die für den Vertragspartner gemacht
            wurden und für allfällige Ersatzansprüche jeglicher Art zu.
          </p>
          <p>
            9.2 Wird das Service im Zimmer des Vertragspartners oder zu
            außergewöhnlichen Tageszeiten (nach 20,00 Uhr und vor 6,00 Uhr)
            verlangt, so ist der Beherberger berechtigt, dafür ein Sonderentgelt
            zu verlangen. Dieses Sonderentgelt ist jedoch auf der
            Zimmerpreistafel auszuzeichnen. Der Beherberger kann diese
            Leistungen aus betrieblichen Gründen auch ablehnen.
          </p>
          <p>
            9.3 Dem Beherberger steht das Recht auf jederzeitige Abr /echnung
            bzw Zwischenab- rechung seiner Leistung zu.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 10 Pflichten des Beherbergers</h3>
          <p>
            10.1 Der Beherberger ist verpflichtet, die vereinbarten Leistungen
            in einem seinem Standard entsprechenden Umfang zu erbr /ingen.
          </p>
          <p>
            10.2 Auszeichnungspflichtige Sonderleistungen des Beherbergers, die
            nicht im Beher- bergungsentgelt inbegriffen sind, sind beispielhaft:
            <br />
            a) Sonderleistungen der Beherbergung, die gesondert in Rechnung
            gestellt werden können, wie die Bereitstellung von Salons, Sauna,
            Hallenbad, Schwimmbad, Solarium, Garagierung usw;
            <br />
            b) für die Bereitstellung von Zusatz- bzw Kinderbetten wird ein
            ermäßigter Preis berechnet.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>
            § 11 Haftung des Beherbergers für Schäden an eingebrachten Sachen
          </h3>
          <p>
            11.1 Der Beherberger haftet gemäß §§ 970 ff ABGB für die vom
            Vertragspartner ein- gebrachten Sachen. Die Haftung des Beherbergers
            ist nur dann gegeben, wenn die Sachen dem Beherberger oder den vom
            Beherberger befugten Leuten überge- ben oder an einen von diesen
            angewiesenen oder hiezu bestimmten Ort gebracht worden sind. Sofern
            dem Beherberger der Beweis nicht gelingt, haftet der Beher- berger
            für sein eigenes Verschulden oder das Verschulden seiner Leute sowie
            der aus- und eingehende Personen. Der Beherberger haftet gemäß § 970
            Abs 1 ABGB höchstens bis zu dem im Bundesgesetz vom 16. November
            1921 über die Haftung der Gastwirte und anderer Unternehmer in der
            jeweils geltenden Fassung festgesetzten Betrag. Kommt der
            Vertragspartner oder der Gast der Aufforderung des Beherbergers,
            seine Sachen an einem besonderen Aufbewahrungsort zu hin- terlegen
            nicht unverzüglich nach, ist der Beherberger aus jeglicher Haftung
            be- freit. Die Höhe einer allfälligen Haftung des Beherbergers ist
            maximal mit der Haftpflichtversicherungssumme des jeweiligen
            Beherbergers begrenzt. Ein Ver- schulden des Vertragspartners oder
            Gastes ist zu berücksichtigen.
          </p>
          <p>
            11.2 Die Haftung des Beherbergers ist für leichte Fahrlässigkeit
            ausgeschlossen. Ist der Vertragspartner ein Unternehmer wird die
            Haftung auch für grobe Fahrläs- sigkeit ausgeschlossen. In diesem
            Fall trägt der Vertragspartner die Beweislast für das Vorliegen des
            Verschuldens. Folgeschäden oder indirekte Schäden sowie entgangene
            Gewinne werden keinesfalls ersetzt.
          </p>
          <p>
            11.3 Für Kostbarkeiten, Geld und Wertpapiere haftet der Beherberger
            nur bis zum Be- trag von derzeit € 550,–. Der Beherberger haftet für
            einen darüber hinausgehen- den Schaden nur in dem Fall, dass er
            diese Sachen in Kenntnis ihrer Beschaffen- heit zur Aufbewahrung
            übernommen hat oder in dem Fall, dass der Schaden von ihm selbst
            oder einen seiner Leute verschuldet wurde. Die Haftungsbeschränkung
            gemäß 12.1 und 12.2 gilt sinngemäß.
          </p>
          <p>
            11.4 Die Verwahrung von Kostbarkeiten, Geld und Wertpapieren kann
            der Beherber- ger ablehnen, wenn es sich um wesentlich wertvollere
            Gegenstände handelt, als Gäste des betreffenden
            Beherbergungsbetriebes gewöhnlich in Verwahrung ge- ben.
          </p>
          <p>
            11.5 In jedem Fall der übernommenen Aufbewahrung ist die Haftung
            ausgeschlossen, wenn der Vertragspartner und/oder Gast den
            eingetretenen Schaden ab Kenntnis nicht unverzüglich dem Beherberger
            anzeigt. Überdies sind diese Ansprüche in- nerhalb von drei Jahren
            ab Kenntnis oder möglicher Kenntnis durch den Ver- tragspartner bzw
            Gast gerichtlich geltend zu machen; sonst ist das Recht erlo- schen.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 12 Haftungsbeschränkungen</h3>
          <p>
            12.1 Ist der Vertragspartner ein Konsument, wird die Haftung des
            Beherbergers für leichte Fahrlässigkeit, mit Ausnahme von
            Personenschäden, ausgeschlossen.
          </p>
          <p>
            12.2 Ist der Vertragspartner ein Unternehmer, wird die Haftung des
            Beherbergers für leichte und grobe Fahrlässigkeit ausgeschlossen. In
            diesem Fall trägt der Ver- tragspartner die Beweislast für das
            Vorliegen des Verschuldens. Folgeschäden, immaterielle Schäden oder
            indirekte Schäden sowie entgangene Gewinne werden nicht ersetzt. Der
            zu ersetzende Schaden findet in jedem Fall seine Grenze in der Höhe
            des Vertrauensinteresses.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 13 Tierhaltung</h3>
          <p>
            13.1 Tiere dürfen nur nach vorheriger Zustimmung des Beherbergers
            und allenfalls gegen eine besondere Vergütung in den
            Beherbergungsbetrieb gebracht werden.
          </p>
          <p>
            13.2 Der Vertragspartner, der ein Tier mitnimmt, ist verpflichtet,
            dieses Tier während seines Aufenthaltes ordnungsgemäß zu verwahren
            bzw zu beaufsichtigen oder dieses auf seine Kosten durch geeignete
            Dritte verwahren bzw beaufsichtigen zu lassen.
          </p>
          <p>
            13.3 Der Vertragspartner bzw Gast, der ein Tier mitnimmt, hat über
            eine entsprechen- de Tier-Haftpflichtversicherung bzw eine
            Privat-Haftpflichtversicherung, die auch mögliche durch Tiere
            verursachte Schäden deckt, zu verfügen. Der Nach- weis der
            entsprechenden Versicherung ist über Aufforderung des Beherbergers
            zu erbr /ingen.
          </p>
          <p>
            13.4 Der Vertragspartner bzw sein Versicherer haften dem Beherberger
            gegenüber zur ungeteilten Hand für den Schaden, den mitgebrachte
            Tiere anrichten. Der Scha- den umfasst insbesondere auch jene
            Ersatzleistungen des Beherbergers, die der Beherberger gegenüber
            Dritten zu erbr /ingen hat.
          </p>
          <p>
            13.5 In den Salons, Gesellschafts-, Restauranträumen und
            Wellnessbereichen dürfen sich Tiere nicht aufhalten.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 14 Verlängerung der Beherbergung</h3>
          <p>
            14.1 Der Vertragspartner hat keinen Anspruch darauf, dass sein
            Aufenthalt verlängert wird. Kündigt der Vertragspartner seinen
            Wunsch auf Verlängerung des Aufent- halts rechtzeitig an, so kann
            der Beherberger der Verlängerung des Beherber- gungsvertrages
            zustimmen. Den Beherberger trifft dazu keine Verpflichtung.
          </p>
          <p>
            14.2 Kann der Vertragspartner am Tag der Abr /eise den
            Beherbergungsbetrieb nicht verlassen, weil durch unvorhersehbare
            außergewöhnliche Umstände (zB extremer Schneefall, Hochwasser etc)
            sämtliche Abr /eisemöglichkeiten gesperrt oder nicht benutzbar sind,
            so wird der Beherbergungsvertrag für die Dauer der Unmöglich- keit
            der Abr /eise automatisch verlängert. Eine Reduktion des Entgelts
            für diese Zeit ist allenfalls nur dann möglich, wenn der
            Vertragspartner die angebotenen Leistungen des
            Beherbergungsbetriebes infolge der außergewöhnlichen Witte-
            rungsverhältnisse nicht zur Gänze nutzen kann. Der Beherberger ist
            berechtigt mindestens jenes Entgelt zu begehren, das dem gewöhnlich
            verrechneten Preis in der Nebensaison entspricht.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>
            § 15 Beendigung des Beherbergungsvertrages – Vorzeitige Auflösung
          </h3>
          <p>
            15.1 Wurde der Beherbergungsvertrag auf bestimmte Zeit
            abgeschlossen, so endet er mit Zeitablauf.
          </p>
          <p>
            15.2 Reist der Vertragspartner vorzeitig ab, so ist der Beherberger
            berechtigt, das volle vereinbarte Entgelt zu verlangen. Der
            Beherberger wird in Abzug br /ingen, was er sich infolge der
            Nichtinanspruchnahme seines Leistungsangebots erspart oder was er
            durch anderweitige Vermietung der bestellten Räume erhalten hat.
            Eine Ersparnis liegt nur dann vor, wenn der Beherbergungsbetrieb im
            Zeitpunkt der Nichtinanspruchnahme der vom Gast bestellten
            Räumlichkeiten vollständig aus- gelastet ist und die Räumlichkeit
            auf Grund der Stornierung des Vertragspartners an weitere Gäste
            vermietet werden kann. Die Beweislast der Ersparnis trägt der
            Vertragspartner.
          </p>
          <p>
            15.3 Durch den Tod eines Gastes endet der Vertrag mit dem
            Beherberger.
          </p>
          <p>
            15.4 Wurde der Beherbergungsvertrag auf unbestimmte Zeit
            abgeschlossen, so können die Vertragsparteien den Vertrag, bis 10.00
            Uhr des dritten Tages vor dem beab- sichtigten Vertragsende,
            auflösen.
          </p>
          <p>
            15.5 Der Beherberger ist berechtigt, den Beherbergungsvertrag mit
            sofortiger Wirkung aus wichtigem Grund aufzulösen, insbesondere wenn
            der Vertragspartner bzw der Gast
            <br />
            a) von den Räumlichkeiten einen erheblich nachteiligen Gebrauch
            macht oder durch sein rücksichtsloses, anstößiges oder sonst grob
            ungehöriges Verhal- ten den übr /igen Gästen, dem Eigentümer, dessen
            Leute oder den im Beher- bergungsbetrieb wohnenden Dritten gegenüber
            das Zusammenwohnen ver- leidet oder sich gegenüber diesen Personen
            einer mit Strafe bedrohten Hand- lung gegen das Eigentum, die
            Sittlichkeit oder die körperliche Sicherheit schuldig macht;
            <br />
            b) von einer ansteckenden Krankheit oder eine Krankheit, die über
            die Beher- bergungsdauer hinausgeht, befallen wird oder sonst
            pflegedürftig wird;
            <br />
            c) die vorgelegten Rechnungen bei Fälligkeit innerhalb einer
            zumutbar gesetz-
            <br />
            ten Frist (3 Tage) nicht bezahlt.
          </p>
          <p>
            15.6 Wenn die Vertragserfüllung durch ein als höhere Gewalt zu
            wertendes Ereignis (zB Elementarereignisse, Streik, Aussperrung,
            behördliche Verfügungen etc) unmöglich wird, kann der Beherberger
            den Beherbergungsvertrag jederzeit ohne Einhaltung einer
            Kündigungsfrist auflösen, sofern der Vertrag nicht bereits nach dem
            Gesetz als aufgelöst gilt, oder der Beherberger von seiner
            Beherbergungs- pflicht befreit ist. Etwaige Ansprüche auf
            Schadenersatz etc des Vertragspartners sind ausgeschlossen.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 16 Erkrankung oder Tod des Gastes</h3>
          <p>
            16.1 Erkrankt ein Gast während seines Aufenthaltes im
            Beherbergungsbetrieb, so wird der Beherberger über Wunsch des Gastes
            für ärztliche Betreuung sorgen. Ist Ge- fahr in Verzug, wird der
            Beherberger die ärztliche Betreuung auch ohne besonde- ren Wunsch
            des Gastes veranlassen, dies insbesondere dann, wenn dies notwen-
            dig ist und der Gast hiezu selbst nicht in der Lage ist.
          </p>
          <p>
            16.2 Solange der Gast nicht in der Lage ist, Entscheidungen zu
            treffen oder die Ange- hörigen des Gastes nicht kontaktiert werden
            können, wird der Beherberger auf Kosten des Gasten für ärztliche
            Behandlung sorgen. Der Umfang dieser Sorge- maßnahmen endet jedoch
            in dem Zeitpunkt, in dem der Gast Entscheidungen treffen kann oder
            die Angehörigen vom Krankheitsfall benachrichtigt worden sind.
          </p>
          <p>
            16.3 Der Beherberger hat gegenüber dem Vertragspartner und dem Gast
            oder bei To- desfall gegen deren Rechtsnachfolger insbesondere für
            folgende Kosten Ersatz- ansprüche:
            <br />
            a) offene Arztkosten, Kosten für Krankentransport, Medikamente und
            Heilbe- helfe
            <br />
            b) notwendig gewordene Raumdesinfektion,
            <br />
            c) unbr /auchbar gewordene Wäsche, Bettwäsche und Betteinrichtung,
            anderen-
            <br />
            falls für die Desinfektion oder gründliche Reinigung all dieser
            Gegenstände,
            <br />
            d) Wiederherstellung von Wänden, Einrichtungsgegenständen, Teppichen
            usw, soweit diese im Zusammenhang mit der Erkrankung oder den
            Todesfall ver-
            <br />
            unreinigt oder beschädigt wurden,
            <br />
            e) Zimmermiete, soweit die Räumlichkeit vom Gast in Anspruch
            genommen
            <br />
            wurde, zuzüglich allfälliger Tage der Unverwendbarkeit der Räume
            wegen
            <br />
            Desinfektion, Räumung o. ä,
            <br />
            f) allfällige sonstige Schäden, die dem Beherberger entstehen.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 17 Erfüllungsort, Gerichtsstand und Rechtswahl</h3>
          <p>
            17.1 Erfüllungsort ist der Ort, an dem der Beherbergungsbetrieb
            gelegen ist.
            <br />
            17.2 Dieser Vertrag unterliegt österreichischem formellen und
            materiellen Recht unter Ausschluss der Regeln des Internationalen
            Privatrechts (insb IPRG und EVÜ) sowie UN-Kaufrecht.
          </p>
          <p>
            17.3 Ausschließlicher Gerichtsstand ist im zweiseitigen
            Unternehmergeschäft der Sitz des Beherbergers, wobei der Beherberger
            überdies berechtigt ist, seine Rechte auch bei jedem anderem
            örtlichem und sachlich zuständigem Gericht geltend zu machen.
          </p>
          <p>
            17.4 Wurde der Beherbergungsvertrag mit einem Vertragspartner, der
            Verbr /aucher ist und seinen Wohnsitz bzw gewöhnlichen Aufenthalt in
            Österreich hat, geschlos- sen, können Klagen gegen den Verbr /aucher
            ausschließlich am Wohnsitz, am ge- wöhnlichen Aufenthaltsort oder am
            Beschäftigungsort des Verbr /auchers einge- br /acht werden.
          </p>
          <p>
            17.5 Wurde der Beherbergungsvertrag mit einem Vertragspartner, der
            Verbr /aucher ist und seinen Wohnsitz in einem Mitgliedsstaat der
            Europäischen Union (mit Aus- nahme Österreichs), Island, Norwegen
            oder der Schweiz, hat, ist das für den Wohnsitz des Verbr /auchers
            für Klagen gegen den Verbr /aucher örtlich und sach- lich zuständige
            Gericht ausschließlich zuständig.
          </p>
        </div>
      </div>

      <div>
        <div>
          <h3>§ 18 Sonstiges</h3>
          <p>
            18.1 Sofern die obigen Bestimmungen nichts Besonderes vorsehen,
            beginnt der Lauf einer Frist mit Zustellung des die Frist
            anordnenden Schriftstückes an die Ver- tragspartner, welche die
            Frist zu wahren hat. Bei Berechnung einer Frist, welche nach Tagen
            bestimmt ist, wird der Tag nicht mitgerechnet, in welchen der Zeit-
            punkt oder die Ereignung fällt, nach der sich der Anfang der Frist
            richten soll. Nach Wochen oder Monaten bestimmte Fristen beziehen
            sich auf denjenigen Ta- ge der Woche oder des Monates, welcher durch
            seine Benennung oder Zahl dem Tage entspricht, von welchem die Frist
            zu zählen ist. Fehlt dieser Tag in dem Monat, ist der in diesem
            Monat letzte Tag maßgeblich.
          </p>
          <p>
            18.2 Erklärungen müssen dem jeweils anderen Vertragspartner am
            letzten Tag der Frist (24 Uhr) zugegangen sein.
          </p>
          <p>
            18.3 Der Beherberger ist berechtigt, gegen Forderung des
            Vertragspartners mit eige- nen Forderungen aufzurechnen. Der
            Vertragspartner ist nicht berechtigt mit eige- nen Forderungen gegen
            Forderungen des Beherbergers aufzurechnen, es sei denn, der
            Beherberger ist zahlungsunfähig oder die Forderung des
            Vertragspartners ist gerichtlich festgestellt oder vom Beherberger
            anerkannt.
          </p>
          <p>
            18.4 Im Falle von Regelungslücken gelten die entsprechenden
            gesetzlichen Bestim- mungen.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
