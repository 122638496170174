import {createAction} from '../../utils/reducer/reducer.utils'
import { FORM_INPUT_ACTION_TYPES } from "./form-input.types";

export const setCurrentGender = (gender) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_GENDER, gender)

export const setCurrentFirstName = (firstName) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_FIRST_NAME, firstName)

export const setCurrentLastName = (lastName) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_LAST_NAME, lastName)

export const setCurrentBirthday = (birthday) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_BIRTHDAY, birthday)

export const setCurrentNationality = (nationality) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_NATIONALITY, nationality)

export const setCurrentAddress = (address)  => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_ADDRESS, address)

export const setCurrentCity = (city) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_CITY, city)

export const setCurrentPlz = (plz) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_PLZ, plz)

export const setCurrentStateAddress = (state) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_STATE_ADDRESS, state)

export const setCurrentCountryAddress = (country) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_COUNTRY_ADDRESS, country)

export const setCurrentArrivalDate = (arrivalDate) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_ARRIVAL_DATE, arrivalDate)

export const setCurrentDepartureDate = (departureDate) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_DEPARTURE_DATE, departureDate)

export const setCurrentIdentityDoc = (identityDoc) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_IDENTITY_DOC, identityDoc)

export const setCurrentIdentityDocUrl = (identityDocUrl) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_IDENTITY_URL, identityDocUrl)

export const setCurrentSignature = (signature) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_SIGNATURE, signature)

export const setCurrentSignatureUrl = (signatureUrl) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_SIGNATURE_URL, signatureUrl)

export const setCurrentAdditionalGuests = (additionalGuests) => 
    createAction(FORM_INPUT_ACTION_TYPES.SET_ADDITIONAL_GUESTS, additionalGuests)