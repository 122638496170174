import styled from "styled-components";
import { COLORS } from "../../colors";

export const InfoContainer = styled.div`

    background: ${({lightBg}) => (lightBg ? `${COLORS.secondary}` : `${COLORS.tertiary}`)};

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }

`

export const InfoWrapper = styled.div`

    display: grid;
    z-index: 1;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (min-width: 768px) {
        height: 95vh;
    }

`

export const InfoRow = styled.div`

    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};     
    }

`

export const Column1 = styled.div`

    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;

`

export const Column2 = styled.div`

    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;

`

export const TextWrapper = styled.div`

    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;

`

export const TopLine = styled.p`

    color: ${({lightBg}) => (lightBg ? `${COLORS.tertiary}` : `${COLORS.secondary}`)};
    font-size: 1rem;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;

`

export const Heading = styled.h1`

    margin-bottom: 24px;
    font-size: 2.5rem;
    line-height: 1.1;
    font-weight: 600;
    color: #000;

    @media screen and (max-width: 768px) {
        font-size: 32px;     
    }

`

export const Subtitle = styled.p`

    max-width: 440px;
    margin-bottom: 35px;
    font-size: 1rem;
    line-height: 24px;
    color: #000;

`

export const BtnWrap = styled.div`

    display: flex;
    justify-content: flex-start;
    gap: 20px;
    @media screen and (max-width: 400px) {
        flex-direction: column;
        flex-wrap: wrap    
    }

`

export const ImgWrap = styled.div`

    max-width: 500px;
    height: 100%;
    margin-right: 50px;
    @media screen and (max-width: 768px) {
        margin-right: 0px;     
    }

`

export const Img = styled.img`

    width: 100%;
    margin: 0 0 10px 0;
    border-radius: 50%;

`