import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCurrentUser } from '../store/user/user.selector';

const CreateBookingPage = () => {

    const currentUser = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    useEffect(()=> {
        if (!currentUser) {
          navigate('/')
        } else if (currentUser.subAccOf) {
          navigate('/')
        } 
    }, [])

  return (
    <div>
        <h1>Create new Booking</h1>
        <form>
            <label htmlFor='bookingnr'>Buchungsnummer</label>
            <input
            type="text"
            name="bookingnr"
            // onChange={handleChange}
            
            >
            </input>
            <label htmlFor='password'>Passwort</label>
            <input
            type="text"
            name="password"
            // onChange={handleChange}
            
            >
            </input>
            <label htmlFor='nukicode'>Nuki Invite Code</label>
            <input
            type="text"
            name="nukicode"
            // onChange={handleChange}
            
            >
            </input>
            <button type='submit'>Erstellen</button>
        </form>
    </div>
  )
}

export default CreateBookingPage