import React, {useState} from 'react'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import { homeObjOne} from '../components/InfoSection/Data'
import Navbar from '../components/Navbar'
import Services from '../components/Services'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer/footer.component'
import MapSection from '../components/MapSection/map-section.component'
import { ArticleSection } from '../components/ArticleSection/article-section.component'
import { mapData } from '../components/MapSection/Data'
import LanguageSwitcherSelector from '../components/language-switcher/language-switcher.component'

const Home = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <LanguageSwitcherSelector />
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle} transparencyEffectOff={false} />
    <HeroSection />
    <InfoSection {...homeObjOne}/>
    <ArticleSection />
    <MapSection {...mapData} />
    <Footer />
    </>
  )
}

export default Home