import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Header,
  LanguageSwitcherContainer,
  LanguageSwitcherText,
  HeadlineContainer,
  StrongHeadline,
  SubheadLine,
  CheckinForm,
  Body,
  SubmitButton,
  SubmitLink,
} from "../Checkin/checkin.styles";

import { ReactComponent as BurgerIcon } from "../assets/burger.svg";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../store/user/user.selector";
import GuestLoginForm from "../components/LoginFormGuest/loginform-guest.component";
import { useTranslation } from "react-i18next";
import LanguageSwitcherSelector from "../components/language-switcher/language-switcher.component";

const LoginGuest = () => {
    const currentUser = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    useEffect(()=> {
        currentUser && navigate('/checkintest')
    }, [currentUser])

    const {t} = useTranslation()

  return (
    <Fragment>
      <Header>
        <LanguageSwitcherContainer>
          <LanguageSwitcherSelector />
        </LanguageSwitcherContainer>
        {/* <button
          style={{
            width: "25px",
            height: "25px",
            margin: "20px",
            backgroundColor: "transparent",
            border: "0",
            cursor: "pointer",
          }}
        >
          <BurgerIcon />
        </button> */}
      </Header>
      <Body>
        <HeadlineContainer>
          <StrongHeadline>Check In</StrongHeadline>
          <SubheadLine>
            {t('checkin_login_subheadline1')} <br /> <br /> {t('checkin_login_subheadline2')} 
          </SubheadLine>
        </HeadlineContainer>
        <GuestLoginForm />
        <SubmitButton inverted="true" style={{ display: 'flex', margin:'auto' }}>
        <SubmitLink style={{ display: 'flex', margin:'auto'}} inverted="true" to="/">
          {t('back_to_home')}
        </SubmitLink>
      </SubmitButton>
        {/* <StyleKreis1 />
        <StyleKreis2 />
        <StyleKreis3 /> */}
      </Body>
    </Fragment>
  );
};

export default LoginGuest;

