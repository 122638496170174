import styled from "styled-components";
import schnitzelImg from '../../assets/schnitzel.jpeg';

export const CarouselElement = styled.div`

    width: 100%;
    background-image: url(${(props) => props.image});
    background-size: cover;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-height: 600px;
    margin: 0 16px;
    flex-direction: column;
    
    @media screen and (max-width: 769px){
        height: 100vw;
        width: 100%;
        margin: 0;
    }

`

export const CarouselContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow:hidden;
    width: 100vw;
    margin-bottom:-10vh;
    aspect-ratio : 1 / 1;
    @media screen and (min-width: 769px){
        display: none;
    }

`

export const CarouselItemContainer = styled.div`

    white-space: nowrap;
    transition: transform 0.3s;

`