import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CheckIn from "./Checkin/checkin.component";
import CheckinFinal from "./CheckinFinal/checkin-final.component";
import LoginGuest from "./login-guest/login-guest.component";
import Home from "./pages";
import Article from "./pages/article";
import AdminLogin from "./pages/admin-login";
import CreateBookingPage from "./pages/admin-create";
import Imprint from "./pages/imprint";
import TermsAndConditions from "./pages/termsandconditions";

function App() {
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="admin" element={<AdminLogin />} />
        <Route path="/admin/create-booking" element={<CreateBookingPage />} />
        <Route path="article">
          <Route path=":article" element={<Article />} />
        </Route>
        <Route path="login" element={<LoginGuest />} />
        <Route path="checkintest" element={<CheckIn />} />
        <Route path="checkin-final" element={<CheckinFinal />} />
        <Route path="imprint" element={<Imprint />} />
        <Route path="agb" element={<TermsAndConditions />} />
      </Routes>
    </Router>
  );
}

export default App;
