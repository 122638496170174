import { compose, createStore, applyMiddleware } from "redux";
import {persistStore, persistReducer} from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

import logger from "redux-logger";

//SAGA
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from "./root-saga";

import { rootReducer } from "./root-reducer";

const persistConfig = {
    key: 'root',
    storage : sessionStorage
}

//SAGA
const sagaMiddleware = createSagaMiddleware()

const persistedReducer = persistReducer(persistConfig, rootReducer)

//SAGA
const middleWares = [process.env.NODE_ENV === 'development' && logger, sagaMiddleware].filter(Boolean);

const composeEnhancer = (process.env.NODE_ENV === 'development' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const composedEnhancers = composeEnhancer(applyMiddleware(...middleWares))

export const store = createStore(persistedReducer, undefined, composedEnhancers)

//SAGA
sagaMiddleware.run(rootSaga)

export const persistore = persistStore(store)