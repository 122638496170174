import React from 'react'
import { FaPhone, FaEnvelope } from 'react-icons/fa'
import { FooterContainer, FooterLinkS, FooterLinkR, FooterLinkTitle, FooterLinksContainer, FooterLinkItems, FooterLinksWrapper, FooterWrap, WebsiteRights, SocialIconLink, SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap } from './footer.styles'
import { animateScroll as scroll } from 'react-scroll'

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop()
    }

  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>About</FooterLinkTitle>
                        <FooterLinkS offset={-80} to='about'>About us</FooterLinkS>
                        <FooterLinkS offset={-80} to='advice'>Tipps | Tricks</FooterLinkS>
                        <FooterLinkS offset={-80} to='location'>Location</FooterLinkS>
                        <FooterLinkR to='/login'>Checkin</FooterLinkR>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>Legal</FooterLinkTitle>
                        <FooterLinkR to='/agb'>AGBs</FooterLinkR>
                        <FooterLinkR to='/imprint'>Impressum</FooterLinkR>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                        Treustrasse 10
                    </SocialLogo>
                    <WebsiteRights>
                        Max Reitner © {new Date().getFullYear()}
                    </WebsiteRights>
                    <SocialIcons>
                    <SocialIconLink href="mailto:max.reitner@gmx.at" target='_blank' aria-label='Mail'>
                            <FaEnvelope />
                        </SocialIconLink>
                        <SocialIconLink href="tel:+1800229933" target='_blank' aria-label='Phone'>
                            <FaPhone />
                        </SocialIconLink>
                        
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer