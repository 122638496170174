import React, {useState} from 'react'

import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer/footer.component'
import ArticlePage from '../components/ArticlePage/articlepage.component'
import HeroSection from '../components/HeroSection'

const Article = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle}  transparencyEffectOff={true}/>
    <ArticlePage />
    <Footer />
    </>
  )
}

export default Article