import { FORM_INPUT_ACTION_TYPES } from "./form-input.types";

const INITIAL_STATE = {
  gender: "",
  firstName: "",
  lastName: "",
  birthday: "",
  nationality: "",
  address: "",
  city: "",
  plz: "",
  stateAdress: "",
  countryAddress: "",
  arrivalDate: "",
  departureDate: "",
  identityDoc: null,
  identityDocUrl: "",
  signature: null,
  signatureUrl: "",
  additionalGuests: []
};

export const formInputReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FORM_INPUT_ACTION_TYPES.SET_GENDER:
      return { ...state, gender: payload };
    case FORM_INPUT_ACTION_TYPES.SET_FIRST_NAME:
      return { ...state, firstName: payload };
    case FORM_INPUT_ACTION_TYPES.SET_LAST_NAME:
      return { ...state, lastName: payload };
    case FORM_INPUT_ACTION_TYPES.SET_BIRTHDAY:
      return { ...state, birthday: payload };
    case FORM_INPUT_ACTION_TYPES.SET_NATIONALITY:
      return { ...state, nationality: payload };
    case FORM_INPUT_ACTION_TYPES.SET_ADDRESS:
      return { ...state, address: payload };
    case FORM_INPUT_ACTION_TYPES.SET_CITY:
      return { ...state, city: payload };
    case FORM_INPUT_ACTION_TYPES.SET_PLZ:
      return { ...state, plz: payload };
    case FORM_INPUT_ACTION_TYPES.SET_STATE_ADDRESS:
      return { ...state, stateAdress: payload };
    case FORM_INPUT_ACTION_TYPES.SET_COUNTRY_ADDRESS:
      return { ...state, countryAddress: payload };
    case FORM_INPUT_ACTION_TYPES.SET_ARRIVAL_DATE:
      return { ...state, arrivalDate: payload };
    case FORM_INPUT_ACTION_TYPES.SET_DEPARTURE_DATE:
      return { ...state, departureDate: payload };
    case FORM_INPUT_ACTION_TYPES.SET_IDENTITY_DOC:
      return { ...state, identityDoc: payload };
    case FORM_INPUT_ACTION_TYPES.SET_IDENTITY_URL:
      return { ...state, identityDocUrl: payload };
    case FORM_INPUT_ACTION_TYPES.SET_SIGNATURE:
      return { ...state, signature: payload };
    case FORM_INPUT_ACTION_TYPES.SET_SIGNATURE_URL:
      return { ...state, signatureUrl: payload };
    case FORM_INPUT_ACTION_TYPES.SET_ADDITIONAL_GUESTS:
      return { ...state, additionalGuests: payload };

    default:
      return state;
  }
};
