import React, {useState} from 'react'
import { useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import { CarouselContainer, CarouselElement, CarouselItemContainer } from './article-slider.styles'
import { useNavigate } from 'react-router-dom'
import { ArticleHR, ArticleH1 } from '../ArticleSection/article-section.styles'
import { animateScroll as scroll } from 'react-scroll'
import './article-slider.css'

export const CarouselItem = ({ h1, link, image }) => {
  const navigate = useNavigate();
  const toggleTopScroll = () => {
    scroll.scrollToTop()
}
  return (
    <CarouselElement onClick={() => {
      navigate(`/${link}`);
      toggleTopScroll();
      }} image={image}>
      <ArticleH1>{h1}</ArticleH1>
      <ArticleHR />
    </CarouselElement>
  )
}

const Carousel = ({children}) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false)

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 1500);
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  })

  return (
    <CarouselContainer 
      {...handlers}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
      >
      <CarouselItemContainer activeIndex={activeIndex} style={{transform: `translateX(-${activeIndex * 100}%)`}}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {width:'100%'});
        })}
      </CarouselItemContainer>
    </CarouselContainer>
  )
}

export default Carousel