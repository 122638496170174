import { TileLayer, Marker, Popup  } from 'react-leaflet'
import { MarkerStyled, OpenStreetMap, TileLayerStyled } from "./mapelement.styles";
import 'leaflet/dist/leaflet.css';
import './map.css'
import React, { createRef } from 'react';

export const flatLocation = {
  address: "Treustraße 10, Wien, Österreich",
  lat: 48.2275,
  lng: 16.3683,
};
const center = {
    address: "Schottenring",
  lat: 48.216814,
  lng: 16.370861,
}

const MapElement = () => {
    
    const outerRef = createRef()

    return (
    <OpenStreetMap className='openStreetMapDiv' center={[center.lat, center.lng]} zoom={13} scrollWheelZoom={false} >
  
  <TileLayerStyled
    // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
   />
<MarkerStyled position={[flatLocation.lat, flatLocation.lng]}>
</MarkerStyled>
</OpenStreetMap>
)};

export default MapElement