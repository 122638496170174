import styled from "styled-components";
import { device } from "../Checkin/checkin.styles";

export const LoginFormContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
  padding: 0;
`;

export const LoginInputLabel = styled.label`
  display: flex;
  font-size: 10pt;
  font-weight: 600;
  color: #000;
  min-width: 50%;
  border: solid #000;
  margin-left: 0.8rem;
  border-width: 0 1.5px 0 0;
  @media ${device.laptopS} {
    min-width: 50%;
    padding-top: 1px;
    font-size: 8pt;
    width: 75px;
  }
`;

export const LoginTextInput = styled.input`
  text-align: right;
  outline: none;
  border: none;
  display: flex;
  font-size: 11pt;
  width: 100%;
  margin-right: 0.8rem;
  @media ${device.laptopS} {
    font-size: 9pt;
  }
`;

export const LoginInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.8rem 0;
  border: 1px solid #000;
  height: 1rem;
  margin-top: 1.5rem;
  margin-left: 0;
  @media (min-width: 887px) {
    width: 400px;
  }
  @media (min-width: 768px) {
    width: 340px;
  }
  @media ${device.tablet} {
    margin-left: 0;
    width: 100%;
  } ;
`;

export const StyleKreis1 = styled.div`
  position: absolute;
  right: -200px;
  bottom: -200px;
  width: 400px;
  height: 400px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  background: #980202;
`;

export const StyleKreis2 = styled.div`
  z-index:-100;
  position: absolute;
  top: -31%;
  left: 15%;
  width: 70%;
  height: 50%;
  -webkit-border-radius: 40%;
  -moz-border-radius: 40%;
  border-radius: 250px;
  background: #e3c988;

  @media (max-width: 768px) {
    top: -32%;
    left: 15%;
    width: 70%;
    height: 50%;
    -webkit-border-radius: 40%;
     -moz-border-radius: 40%;
    border-radius: 20%;
  }
  
`;

export const StyleKreis3 = styled.div`
  position: absolute;
  left: -200px;
  bottom: -200px;
  width: 400px;
  height: 400px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;
  background: navy;
`;
