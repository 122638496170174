import styled from "styled-components";
import { COLORS } from "../../colors";

export const SectionContainer = styled.div`

    /* height: 95vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${COLORS.tertiary};
    padding: 10vh 0;

    @media screen and (max-width: 768px) {
        /* height: 400px; */
    }

    @media screen and (max-width: 480px) {
        /* height: 500px; */
    }

`

export const ArticlesWrapper = styled.div`

    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0;
    
    @media screen and (min-width: 1100px) {
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }

`

export const ArticleCard = styled.div`

    background-image: url(${(props) => props.image});
    background-size: cover;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    aspect-ratio : 1 / 1;
    padding: 30px;
    box-shadow: 2px 3px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

`

export const SectionH1 = styled.h1`
    margin: 0 10vw;
    text-align: center;
    font-size: 2.5rem;
    color:${COLORS.darkText};
    margin-bottom: 30px;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }

`

export const SectionH2 = styled.h2`
    margin: 0 10vw;
    font-size: 1rem;
    font-weight: 500;
    max-width: 600px;
    color:${COLORS.darkText};
    margin-bottom: 80px;
    text-align: center;

`

export const SectionP = styled.p`

    font-size: 1rem;
    text-align: center;

`

export const ArticleH1 = styled.h1`

    margin: 0 20px;
    font-size: 1.1rem;

    color:#fff;
    margin-bottom: 20px;
    text-align: center; 

`

export const ArticleHR = styled.hr`

    height: 1px;
    width:50%;
    margin:0 auto;
    color: #fff;
    background-color: #fff;
    border: 0;

`