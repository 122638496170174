import React from 'react';
import Carousel, { CarouselItem } from '../article-slider/article-slider.component';
import christmasMarketImg from '../../assets/christmas_market.jpg';
import schnitzelImg from '../../assets/schnitzel.jpeg';
import viennaEveningImg from '../../assets/vienna_evening.jpg';
import {SectionContainer, SectionH1, SectionH2, SectionP, ArticleCard, ArticlesWrapper, ArticleH1, ArticleHR} from './article-section.styles'
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll'
    

export const ArticleSection = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const toggleTopScroll = () => {
    scroll.scrollToTop()
}

  return (
    <SectionContainer id='advice'>
    <SectionH1>{t('homepage.advice_section.headline')}</SectionH1>
    <SectionH2>
      <Trans        
        i18nKey="homepage.advice_section.text"
        components={{ break: <br /> }}
        />
    </SectionH2>
    <ArticlesWrapper>
      <ArticleCard image={schnitzelImg} onClick={() => {
        navigate('article/restaurants');
        toggleTopScroll()
        }}>
        <ArticleHR />
        <ArticleH1>{t('homepage.advice_section.1article_headline')}</ArticleH1>
      </ArticleCard>
      <ArticleCard image={viennaEveningImg}>
        <ArticleHR />
        <ArticleH1>{t('homepage.advice_section.2article_headline')}</ArticleH1>
      </ArticleCard>
      <ArticleCard image={christmasMarketImg} onClick={() => {
        navigate('article/christmas');
        toggleTopScroll()
        }}>
        <ArticleHR />
        <ArticleH1>{t('homepage.advice_section.3article_headline')}</ArticleH1>
      </ArticleCard>
    </ArticlesWrapper>
    <Carousel>
      <CarouselItem h1={t('homepage.advice_section.3article_headline')} link={'article/christmas'} image={christmasMarketImg}>Item 1</CarouselItem>
      <CarouselItem h1={t('homepage.advice_section.1article_headline')} link={'article/restaurants'} image={schnitzelImg}>Item 2</CarouselItem>
      <CarouselItem h1={t('homepage.advice_section.2article_headline')} link={'/'} image={viennaEveningImg}>Item 3</CarouselItem>
    </Carousel>
    </SectionContainer>
  )
}
