import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import {COLORS} from '../../colors'

export const NavLogo = styled.img`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 50%;
  margin: auto 0;
  @media screen and (max-width: 768px) {
    height: 30%;
  }
  @media screen and (max-width: 1200px) {
    height: 40%;
    margin-left: 20px;
  }
`;

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? `${COLORS.quinary}` : "transparent")};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0;
  max-width: 1100px;
  @media screen and (min-width: 1500px) {
    margin-left: -115px;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  /* @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  } */
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: 0px;
  font-size: 1rem;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkS)`
  color: ${({ scrollNav }) => (scrollNav ?  `${COLORS.darkText}` : `${COLORS.secondary}`)};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #BC6C25;
  }
`;

export const NavLinksRouter = styled(LinkR)`
  color: ${({ scrollNav }) => (scrollNav ?  `${COLORS.darkText}` : `${COLORS.secondary}`)};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #BC6C25;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  
  background: ${({ scrollNav }) => (scrollNav ?  `${COLORS.tertiary}` : `${COLORS.secondary}`)};
  font-weight: ${({ scrollNav }) => (scrollNav ?  '600' : '500')};
  white-space: nowrap;
  padding: 10px 22px;
  color: ${COLORS.darkText};
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: width 0.2s ease-in-out;
    transform: scale(1.03);
    font-weight: 600;
  }
`;
