

import React from 'react'
import { SectionContainer, SectionWrapper, SectionRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img} from './map.styles'
import { Button, PhoneButton } from '../ButtonElement'
import MapElement from '../MapElement/mapelement.component'
import { flatLocation } from '../MapElement/mapelement.component'
import { useTranslation } from 'react-i18next'

const MapSection = ({lightBg, id, mapStart, topLine, lightText, headline, darkText, description, button1Label, button2Label, primary, dark, dark2}) => {
    var docWidth = document.documentElement.offsetWidth;
    [].forEach.call(
      document.querySelectorAll('*'),
      function(el) {
        if (el.offsetWidth > docWidth) {
          console.log(el);
        }
      }
    );
  
      const {t} = useTranslation();

    return (
    <>
    
    <SectionContainer lightBg = {lightBg} id={id}>
        <SectionWrapper>
            <SectionRow mapStart={mapStart}>
                <Column1>
                    <TextWrapper>
                        <TopLine> {t(topLine)} </TopLine>
                        <Heading lightText={lightText}> {t(headline)} </Heading>
                        <Subtitle darkText={darkText}> {t(description)} </Subtitle>
                        <BtnWrap>
                            <PhoneButton href={`http://maps.apple.com/?q=${flatLocation.lat},${flatLocation.lng}`}
                            smooth={true} 
                            duration={500} 
                            spy={true} 
                            exact='true' 
                            offset={-80}
                            primary={!primary ? 1 : 0}
                            dark={dark ? 1 : 0}
                            dark2={dark2 ? 1 : 0}
                            >
                                {t(button1Label)}
                            </PhoneButton>

                        </BtnWrap>
                    </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                        <MapElement />
                    </ImgWrap>
                </Column2>
            </SectionRow>
        </SectionWrapper>
    </SectionContainer>
    
    </>
  )
}

export default MapSection
