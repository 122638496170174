import styled from 'styled-components';
import { MapContainer, TileLayer, Marker} from 'react-leaflet'


export const OpenStreetMap = styled(MapContainer)`
display: flex;
z-index: 1;
min-height: 40vw;
    margin: 0 0 10px 0;
    border-radius: 25vw;
border: 2px solid #BC6C25;
@media screen and (min-width: 1100px) {
  min-height: 470px;
  border-radius: 500px;
}
@media screen and (max-width: 768px) {
  width: 70vw;
  min-height: 70vw;
  border-radius: 50vw;
}
@media screen and (max-width: 600px) {
  width: 80vw;
  min-height: 80vw;
  border-radius: 50vw;
}
`

export const TileLayerStyled = styled(TileLayer)`
/* max-height: 700px; */
max-width: 1000px;
`

export const MarkerStyled = styled(Marker)`
`

export const PopupStyled = styled.div`
${MarkerStyled} :hover {
  display: block
}
`