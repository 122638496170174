export const FORM_INPUT_ACTION_TYPES = {
    SET_GENDER: 'formInput/SET_GENDER',
    SET_FIRST_NAME: 'formInput/SET_FIRST_NAME',
    SET_LAST_NAME: 'formInput/SET_LAST_NAME',
    SET_BIRTHDAY: 'formInput/SET_BIRTHDAY',
    SET_NATIONALITY: 'formInput/SET_NATIONALITY',
    SET_ADDRESS: 'formInput/SET_ADDRESS',
    SET_CITY: 'formInput/SET_CITY',
    SET_PLZ: 'formInput/SET_PLZ',
    SET_STATE_ADDRESS: 'formInput/SET_STATE_ADDRESS',
    SET_COUNTRY_ADDRESS: 'formInput/SET_COUNTRY_ADDRESS',
    SET_ARRIVAL_DATE: 'formInput/SET_ARRIVAL_DATE',
    SET_DEPARTURE_DATE: 'formInput/SET_DEPARTURE_DATE',
    SET_IDENTITY_DOC: 'formInput/SET_IDENTITY_DOC',
    SET_IDENTITY_URL: 'formInput/SET_IDENTITY_URL',
    SET_SIGNATURE: 'formInput/SET_SIGNATURE',
    SET_SIGNATURE_URL: 'formInput/SET_SIGNATURE_URL',
    SET_ADDITIONAL_GUESTS: 'formInput/SET_ADDITIONAL_GUESTS',
  };