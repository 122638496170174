import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as PageLink } from 'react-router-dom'
import {COLORS} from '../colors'

export const Button = styled(Link)`

    border-radius: 50px;
    background: ${({primary}) => (primary ? `${COLORS.secondary}` : `${COLORS.tertiary}`)};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '10px 30px')};
    color: ${({dark}) => (dark ? `${COLORS.darkText}` : `${COLORS.darkText}`)};
    font-size: ${({primary}) => (primary ? '15px' : '16px')};
    font-weight: 600;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    border: ${`2px solid ${COLORS.tertiary}`}; 

    &:hover {
        transition: width 0.2s ease-in-out;
        font-weight: 600;
        background: ${({primary}) => (primary ? `${COLORS.tertiary}` : `${COLORS.secondary}`)};
  
        cursor: pointer;
    }

`

export const DifferentPageButton = styled(PageLink)`

text-decoration: none;
    border-radius: 50px;
    background: ${({primary}) => (primary ? `${COLORS.tertiary}` : `${COLORS.secondary}`)};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? `${COLORS.darkText}` : `${COLORS.lightText}`)};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '1rem')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? `${COLORS.secondary}` : `${COLORS.primary}`)};
    }

`

export const PhoneButton = styled.a`

text-decoration: none;
    border-radius: 50px;
    background: ${({primary}) => (primary ? `${COLORS.tertiary}` : `${COLORS.secondary}`)};
    white-space: nowrap;
    padding: ${({primary}) => (primary ? '12px 34px' : '10px 30px')};
    color: ${COLORS.darkText};
    font-size: ${({primary}) => (primary ? '1rem' : '0.9rem')};
    outline: none;
    font-weight: ${({primary}) => (primary ? '500' : '600')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${({primary}) => (primary ? `0` : `2px solid ${COLORS.tertiary}`)}; 
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: bg 0.2s ease-in-out;
        background: ${({primary}) => (primary ? `${COLORS.secondary}` : `${COLORS.tertiary}`)};
    }

`