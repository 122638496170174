import React, {useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer/footer.component';

import {
  Header,
  LanguageSwitcherContainer,
  LanguageSwitcherText,
  HeadlineContainer,
  StrongHeadline,
  SubheadLine,
  CheckinForm,
  Body,
  SubmitButton,
  SubmitLink,
} from "../Checkin/checkin.styles";

import { ReactComponent as BurgerIcon } from "../assets/burger.svg";

import { selectCurrentUser } from "../store/user/user.selector";
import AdminLoginForm from '../components/LoginFormAdmin/loginAdmin.component';

import LanguageSwitcherSelector from "../components/language-switcher/language-switcher.component";

const AdminLogin = () => {

    const [isOpen, setIsOpen] = useState(false)
    const currentUser = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    const {t} = useTranslation()

    useEffect(()=> {
      if (!currentUser) {
        navigate('/admin')
      } else if (currentUser.subAccOf) {
        navigate('/')
      } else {
        navigate('/admin/create-booking')
      }
  }, [currentUser])

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    {/* <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle}  transparencyEffectOff={true}/> */}
    <Header>
        <LanguageSwitcherContainer>
          <LanguageSwitcherSelector />
        </LanguageSwitcherContainer>
        <button
          style={{
            width: "25px",
            height: "25px",
            margin: "20px",
            backgroundColor: "transparent",
            border: "0",
            cursor: "pointer",
          }}
        >
          <BurgerIcon />
        </button>
      </Header>
      <Body>
        <HeadlineContainer>
          <StrongHeadline>{t('admin_login_headline')}</StrongHeadline>
          <SubheadLine>
            {t('admin_login_subheadline')}
          </SubheadLine>
        </HeadlineContainer>
        <AdminLoginForm />
        <SubmitButton inverted="true" style={{ display: 'flex', margin:'auto' }}>
        <SubmitLink style={{ display: 'flex', margin:'auto'}} inverted="true" to="/">
          {t('admin_login_back_button')}
        </SubmitLink>
      </SubmitButton>
      </Body>
    {/* <Footer /> */}
    </>
  )
}

export default AdminLogin