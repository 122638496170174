import React, {useState, useEffect} from 'react'
import {FaBars} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { NavbarContainer, Nav, NavLogo, NavItem, NavLinks, MobileIcon, NavMenu, NavBtn, NavBtnLink, NavLinksRouter } from './NavbarElements'
import { animateScroll as scroll, scroller } from 'react-scroll'
import logoBright from '../../assets/Treustrasse_Logo_bright_vector.svg'
import logoDark from '../../assets/Treustrasse_Logo_dark_vector.svg'

import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'


const Navbar = ({toggle, transparencyEffectOff}) => {
    const navigationLinks = [{
        to:'about',
        displayText: 'navbar.link1_text'
    },
    {
        to:'advice',
        displayText: 'navbar.link2_text'
    },
    {
        to:'location',
        displayText: 'navbar.link3_text'
    }]

    const {t} = useTranslation();
    const path = useLocation().pathname;
    const location = path.split('/')[1];

    const [scrollNav, setScrollNav] = useState(transparencyEffectOff)
    const [logo, setLogo] = useState(null)
    const navigate = useNavigate();

    const changeNav = () => {
        if (!transparencyEffectOff) {
        if(window.scrollY > 80) {
            setScrollNav(true)
            setLogo(logoBright)
        } else {
            setScrollNav(false)
            setLogo(logoDark)
        }}
    }

    const toggleHome = () => {
        scroll.scrollToTop()
        navigate('/')
    }

    useEffect(() => {
        if (transparencyEffectOff) {
            setLogo(logoBright)
        } else {
            setLogo(logoDark)
        }
        window.addEventListener('scroll', changeNav)
        
    }, [])

    const goToHomeAndScroll = async (toLocation) => {
        await closeMobile();
        await navigate('/')
        scroller.scrollTo(toLocation, {
            duration: 0,
            delay: 0,
        smooth: false,
        offset: -80
        });
    };
    const closeMobile = () => {};

  return (
    <>
    <IconContext.Provider value={{color: 'fff'}}>
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo src={logo} alt="Logo" onClick={toggleHome} />
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    {
                        navigationLinks.map((navElement) =>{
                            return <NavItem>
                                {location === '' ? (
                                    <NavLinks scrollNav={scrollNav} to={navElement.to} smooth={true} duration={500} spy={true} exact='true' offset={-80}>{t(navElement.displayText)}</NavLinks>
                                    ) : (
                                    <NavLinksRouter scrollNav={scrollNav} onClick={() => {goToHomeAndScroll(navElement.to)}}>{t(navElement.displayText)}</NavLinksRouter>
      )}
                            </NavItem>
                        })
                    }
                </NavMenu>
                <NavBtn>
                    <NavBtnLink scrollNav={scrollNav} to='/login'>Check In</NavBtnLink>
                </NavBtn>
            </NavbarContainer>
        </Nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar