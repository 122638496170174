import hosts from '../../images/img_hosts.webp'
import MapElement from '../MapElement/mapelement.component'
// import svg2 from '../../images/svg-2.svg'
// import svg3 from '../../images/svg-3.svg'

export const mapData = {

    id: 'location',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'homepage.location_section.topline',
    headline: 'homepage.location_section.headline',
    description: 'homepage.location_section.text',
    button1Label: 'homepage.location_section.button1_text',
    button2Label: 'Test',
    mapStart: true,
    dark: false,
    primary: true,
    darkText: true

}

// export const homeObjTwo = {

//     id: 'discover',
//     lightBg: true,
//     lightText: false,
//     lightTextDesc: false,
//     topLine: 'Premium Bank',
//     headline: 'Unlimited Access',
//     description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees',
//     buttonLabel: 'Learn More',
//     imgStart: true,
//     img: svg2,
//     alt: 'graphs',
//     dark: false,
//     primary: false,
//     darkText: true

// }

// export const homeObjThree = {

//     id: 'signup',
//     lightBg: true,
//     lightText: false,
//     lightTextDesc: false,
//     topLine: 'Join our team',
//     headline: 'Creating an account is extremely easy',
//     description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees',
//     buttonLabel: 'Start Now',
//     imgStart: false,
//     img: svg3,
//     alt: 'Paper',
//     dark: false,
//     primary: false,
//     darkText: true

// }