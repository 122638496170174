import styled from "styled-components";
import { MdArrowBack } from "react-icons/md";
import { ReactComponent as Clock } from '../../assets/clockIcon.svg'; 
import { ReactComponent as Pin } from '../../assets/mapPin.svg'; 


export const ClockIcon = styled(Clock)`

  width: 20px;
  margin-top: 5px;
  margin-right: 20px;

`

export const MapPin = styled(Pin)`

  width: 20px;
  margin-right: 20px;

`

export const ArrowBackward = styled(MdArrowBack)`
  margin-right: 8px;
  font-size: 16px;
  padding-top: 1px;
  color: #BC6C25;
`;

export const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  z-index: 1;

  @media screen and (max-width: 768px) {
        padding: 100px 24px;
    }
`;


export const ArticleBody = styled.div`
    margin: 50px auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    flex-wrap: wrap;
    max-width: 1100px;
    padding: 0;
    justify-content: center;
`

export const ArticleElement = styled.div`
    margin-top: 100px;
`

export const ArticleParagraph = styled.p`

    margin: 4rem 0;
    font-size: 1rem;
    word-break: normal;

`

export const OpenHoursContainer = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem 0 2rem 0;

`

export const OpenHoursListContainer = styled.ul`

    line-height: 120%;

`

export const OpenHoursListElement = styled.li`

  list-style-type: none;
  margin: 0;
  padding: 0; 

`

export const AddressContainer = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0 2rem 0;

`

export const TopWrapper = styled.div`
    margin: 180px auto 50px auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    flex-wrap: wrap;
    max-width: 1100px;
    padding: 0;
    justify-content: center;

    @media screen and (max-width: 768px){
      margin: 50px auto;
    }

`

export const ImgWrapper = styled.div`
    margin: 20px auto 0px auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    height: 500px;
    max-width: 1100px;
    justify-content: center;

`

export const ArticleHeadImg = styled.img`

    display: flex;
    width: 100%;
    height: 500px;
    object-fit: cover;
    -o-object-fit: cover;
    border-radius: 25px;
    @media screen and (max-width: 768px) {
        height: 90vw;
        margin: 0;
    }

`

export const ArticleImg = styled.img`

    margin: 2rem auto;
    display: flex;
    justify-self: center;
    align-self: center;
    align-items: center;
    width: 50%;
    border-radius: 25px;
    object-fit: contain;
    -o-object-fit: cover;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`

export const Backlink = styled.a`
    font-size: 16px;
    text-decoration: none;
    margin: 0px;
    color: #BC6C25;

`

export const ArticleH1 = styled.h1`

@media screen and (max-width: 400px) {
        font-size: 1.5rem;
    }

`