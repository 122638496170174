import React from 'react'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img} from './InfoElements'
import { Button } from '../ButtonElement'
import { useTranslation } from 'react-i18next'
const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, button1Label, button2Label, img, alt, primary, dark, dark2}) => {
  
    const {t} = useTranslation();
    return (
    <>
    
    <InfoContainer lightBg = {lightBg} id={id}>
        <InfoWrapper>
            <InfoRow imgStart={imgStart}>
                <Column1>
                    <TextWrapper>
                        <TopLine lightBg = {lightBg}> {t(topLine)} </TopLine>
                        <Heading lightText={lightText}> {t(headline)} </Heading>
                        <Subtitle darkText={darkText}> {t(description)} </Subtitle>
                        <BtnWrap>
                            <Button to='home'
                            smooth={true} 
                            duration={500} 
                            spy={true} 
                            exact='true' 
                            offset={-80}
                            primary={!primary ? 1 : 0}
                            dark={dark ? 1 : 0}
                            dark2={dark2 ? 1 : 0}
                            >
                                {t(button1Label)}
                            </Button>

                            <Button to='home'
                            smooth={true} 
                            duration={500} 
                            spy={true} 
                            exact='true' 
                            offset={-80}
                            primary={!primary ? 1 : 0}
                            dark={dark ? 1 : 0}
                            dark2={dark2 ? 1 : 0}
                            >
                                {t(button2Label)}
                            </Button>
                        </BtnWrap>
                    </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                        <Img src={img} alt={alt}/>
                    </ImgWrap>
                </Column2>
            </InfoRow>
        </InfoWrapper>
    </InfoContainer>
    
    </>
  )
}

export default InfoSection