import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useTranslation} from 'react-i18next'
import {
  CheckinForm,
  SubmitButton,
  SubmitLink,
} from "../../Checkin/checkin.styles";

import {
  LoginFormContainer,
  LoginInputLabel,
  LoginTextInput,
  LoginInputWrapper,
} from "../../login-guest/login-guest.styles";

import { emailSignInStart } from "../../store/user/user.action";
import { selectError } from "../../store/user/user.selector";

const defaultFormFields = {
  email: "",
  password: "",
};

const GuestLoginForm = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;
  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestMail = `${email}@your-bnb.co`
    console.log(requestMail, ' | ', password)
    dispatch(emailSignInStart(requestMail, password));
    resetFormFields();
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <CheckinForm onSubmit={handleSubmit}>
      <LoginFormContainer>
        <LoginInputWrapper>
          <LoginInputLabel htmlFor="email">{t('booking_nr')}</LoginInputLabel>
          <LoginTextInput
            type="text"
            name="email"
            onChange={handleChange}
            value={email}
          ></LoginTextInput>
        </LoginInputWrapper>
        <LoginInputWrapper>
          <LoginInputLabel htmlFor="password">{t('password')}</LoginInputLabel>
          <LoginTextInput
            type="text"
            name="password"
            onChange={handleChange}
            value={password}
          ></LoginTextInput>
        </LoginInputWrapper>
      </LoginFormContainer>
      {/* <button type="submit">Submit</button> */}
      <SubmitButton type="submit" style={{ marginTop: "100px"}}>
      {t('start_checkin')}
      </SubmitButton>
    </CheckinForm>
  );
};

export default GuestLoginForm;
