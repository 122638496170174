import React from "react";
import { ArticleH1, ClockIcon, MapPin, ArticleContainer, Backlink, TopWrapper, ArrowBackward, ImgWrapper, ArticleHeadImg, ArticleImg, ArticleBody, ArticleElement, ArticleParagraph, OpenHoursContainer, OpenHoursListContainer, OpenHoursListElement, AddressContainer } from "./articlepage.styles";
import { useNavigate, useParams } from "react-router-dom";
import christmasImage from '../../assets/christmas_market.jpg'
import { useTranslation, Trans } from "react-i18next";
import LanguageSwitcherSelector from "../language-switcher/language-switcher.component";

const ArticlePage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {article} = useParams();
  let activeArticle = ""
  let elementCount = 0
  switch (article) {
    case 'christmas':
      activeArticle = "article_christmas_markets"
      elementCount = 3
      break;
    case 'restaurants':
      activeArticle = "article_restaurants"
      elementCount = 3
      break;
    case 'todos':
      activeArticle = "article_things_todo"
      elementCount = 3
      break;
    default:
      console.log('Article not found');
  }
  

  return (
    <ArticleContainer>
      <LanguageSwitcherSelector />
      <TopWrapper>
        <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
          <ArrowBackward onClick={() => {
            navigate(-1);
          }} />
          <Backlink
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('article_back_link')}
          </Backlink>
        </div>
        <h1 style={{ margin: '30px 0', color:'#283618' }}>{t(`${activeArticle}.headline`)}</h1>
        <p style={{ fontSize: '18px', wordBreak: 'break-word', color: '#283618' }}>
        {t(`${activeArticle}.short_description`)}
        </p>
      </TopWrapper>
      <ImgWrapper>
        <ArticleHeadImg src={t(`${activeArticle}.main_img_link`)} />
      </ImgWrapper>
      <ArticleBody>
        {[...Array(elementCount).keys()].map((key, index) => (
            <ArticleElement>
            <ArticleH1>{t(`${activeArticle}.${index + 1}_article.title`)}</ArticleH1>
            <OpenHoursContainer>
              <ClockIcon />
              <OpenHoursListContainer>
                <OpenHoursListElement style={{ marginBottom: '5px'}}>
                <Trans
                  i18nKey={`${activeArticle}.${index + 1}_article.time_first_line`}
                  components={{ break: <br />, bold: <b /> }}
                />
                  {/* {t(`${activeArticle}.${index + 1}_article.time_first_line`)} */}
                </OpenHoursListElement>
                <OpenHoursListElement>{t(`${activeArticle}.${index + 1}_article.time_second_line`)}</OpenHoursListElement>
                <OpenHoursListElement>{t(`${activeArticle}.${index + 1}_article.time_third_line`)}</OpenHoursListElement>
              </OpenHoursListContainer>
            </OpenHoursContainer>
            <AddressContainer>
              <MapPin />
              <a style={{color:'black'}} href={t(`${activeArticle}.${index + 1}_article.address_url`)}>{t(`${activeArticle}.${index + 1}_article.address`)}</a>
            </AddressContainer>
  
            <ArticleParagraph>
            {t(`${activeArticle}.${index + 1}_article.text`)}
            </ArticleParagraph>
            <ArticleImg src={t(`${activeArticle}.${index + 1}_article.img_link`)}/>
          </ArticleElement>
          ))
        }
      </ArticleBody>


    </ArticleContainer>
  );
};

export default ArticlePage;
